
import VariableAPI from '../api/variable_api';
import FolderAPI from '../api/folder_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  folders: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  variables: [],
  queryParams: {
    page: 1,
    folder_id_eq: '',
    name_cont: null
  }
};

export const mutations = {
  pushFolder(state, folder) {
    folder.variables = [];
    state.folders.push(folder);
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  updateFolder(state, newItem) {
    const item = state.folders.find(item => item.id === newItem.id);
    if (item) {
      item.name = newItem.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.folders.findIndex(_ => _.id === id);
    state.folders.splice(index, 1);
  },

  deleteVariable(state, id) {
    const folder = state.folders.find(folder => folder.variables.some(_ => _.id === id));
    const index = folder.variables.find(_ => _.id === id);
    folder.variables.splice(index, 1);
  },

  setVariables(state, variables) {
    state.variables = variables;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.folders.splice(oldIndex, 1)[0];
    state.folders.splice(newIndex, 0, movedFolder);
  },

  updateVariableOrder(state, { oldIndex, newIndex }) {
    const movedVariable = state.variables.splice(oldIndex, 1)[0];
    state.variables.splice(newIndex, 0, movedVariable);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getFolders(context, query) {
    try {
      const params = { type: 'variable', name: state.queryParams.name_cont };
      const combinedQuery = { ...query, ...params };
      const response = await FolderAPI.list(combinedQuery);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getVariable(_, id) {
    try {
      return await VariableAPI.get(id);
    } catch (error) {
      return null;
    }
  },

  async updateVariable(context, query) {
    try {
      const response = await VariableAPI.update(query);
      return response;
    } catch (error) {
      return null;
    }
  },

  async createVariable(context, payload) {
    try {
      const response = await VariableAPI.create(payload);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteVariable(context, id) {
    try {
      return await VariableAPI.delete(id);
    } catch (error) {
      return null;
    }
  },

  async copyVariable(context, id) {
    try {
      return await VariableAPI.copy(id);
    } catch (error) {
      return null;
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const response = await FolderAPI.update(payload);
      context.commit('updateFolder', response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async getVariables(context) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const res = await VariableAPI.list(params);

      context.commit('setVariables', res.data);
      context.commit('setMeta', res.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderVariables(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await VariableAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder variables:', error);
    }
  }
};
